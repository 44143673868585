import React from 'react';
import {Link} from 'react-router-dom';

function SinglePageFrame(props: {title?: any, children: React.ReactNode}) {
  const header = props.title?<h1 className="is-size-1">{props.title}</h1>:'';
  return (
    <div className="page simple">
        <header>
          <p>SLM Oslo Medlemskap</p>
        </header>
        <section className="header">
        {header}
        <div className="links"> <Link to="/">&lt; Forside</Link></div>
          </section>
        <section>
        {props.children}
        </section>
        <footer>
           <p>SLM Oslo \\ <a href="https://slmoslo.no/om-klubben/">Om oss</a> \\ <Link to="/personvern">Personvern</Link> \\ <a href="https://slmoslo.no/om-klubben/kontakt-oss/">Kontakt oss</a></p>
        </footer>
    </div>
  );
}

export default SinglePageFrame;