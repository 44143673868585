import { gql } from "@apollo/client";

export const SEARCH = gql`
  query search($query: String!) {
    search(query: $query) {
      id
      firstname
      lastname
      email
      phone
      sensitive
      category
      periodend
    }
  }
`;
