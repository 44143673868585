import React from "react";
import SimplePageFrame from "../components/SimplePageFrame";

function Privacy() {
  return (
    <SimplePageFrame title="Personvern">
      <p>
        Våre medlemmers personinformasjon er hellig for oss. Det er viktig for
        en organisasjon som vår at dette behandles korrekt og diskret.
      </p>
      <p>Denne siden beskriver hvordan vårt medlemsregister behandles.</p>
      <h2 className="is-size-4">Hvem har tilgang til daten?</h2>
      <p>
        Medlemsansvarling er den enese som har full daglig tilgang til
        registeret. President og webansvarlig har i fellesskap tilgang til å
        kunne hente ut en kryptert backup.
      </p>
      <p>
        Ut over disse har de som er frivillig på klubben har begrenset tilgang
        til å søke i registeret og vise noe informasjon, dette inkluderer
        fornavn, eventuelle mellomnavn, første bokstav i etternavn og
        medlemsnummer.
      </p>
      <h2 className="is-size-4">Hvilke persondata lagrer vi?</h2>
      <p>
        Vi lagrer medlemmers navn, telefonnummer og epostadresse. Og
        postadresse til de som har oppgitt dette. 
        I tillegg til den informasjonen som ble oppgitt i søknaden om medlemskap.
      </p>
      <h2 className="is-size-4">Hva er formålet med dataen?</h2>
      <p>Formålet er å kunne holde et medlemsregister til foreningen.</p>
      <h2 className="is-size-4">Hvem deler vi data med?</h2>
      <p>
        Ingen. Våre sammarbeidspartnere må informeres av deg selv at du er
        medlem i klubben for å dra nytte av godene.
      </p>
      <h2 className="is-size-4">Hvor lagres informasjonen?</h2>
      <p>
        Medlemsregisteret ligger på servere i Norge med begrenset fysisk
        tilgang.
      </p>
      <h2 className="is-size-4">Hvordan få slettet data om meg?</h2>
      <p>
        Ta kontakt med medlemsansvarlig på epost og etterspør sletting. Dette
        gjøres også automatisk ved skriftlig utmedling.
      </p>
    </SimplePageFrame>
  );
}

export default Privacy;
