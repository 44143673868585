import { gql } from "@apollo/client";

const ADD_PROFILE = gql`
  mutation registerNewProfile(
    $firstname: String!
    $lastname: String!
    $email: String!
    $phone: String!
    $address: String
    $zip: String
    $city: String
    $country: String
    $sensitive: Boolean
    $captchaValue: String!
    $captchaKey: String!
  ) {
    registerNewProfile(
      profile: {
        firstname: $firstname
        lastname: $lastname
        email: $email
        phone: $phone
        address: $address
        zip: $zip
        city: $city
        country: $country
        sensitive: $sensitive
      }
      captchaValue: $captchaValue
      captchaKey: $captchaKey
    ) {
      key
    }
  }
`;

const GET_PROFILE_CAPTCHA = gql`
  query getCaptcha {
    getProfileCaptcha {
      key
      captcha
    }
  }
`;

const VERIFY_SMS_PROFILE = gql`
  mutation verifyProfile($code: String!, $profileKey: String!) {
    verifyNewProfile(code: $code, profileKey: $profileKey) {
      id
      firstname
      lastname
      email
      phone
      address
      zip
      city
      country
      sensitive
      price
    }
  }
`;

const ADD_MAILINGLIST = gql`
  mutation addMailingList($email: String) {
    addToMailingList(email: $email)
  }
`;

const SAVE_PROFILE = gql`
  mutation UpdateProfile(
    $key: String!
    $id: Int
    $firstname: String!
    $lastname: String!
    $email: String!
    $phone: String!
    $address: String
    $zip: String
    $city: String
    $country: String
    $sensitive: Boolean
  ) {
    updateProfile(
      key: $key
      profile: {
        id: $id
        firstname: $firstname
        lastname: $lastname
        email: $email
        phone: $phone
        address: $address
        zip: $zip
        city: $city
        country: $country
        sensitive: $sensitive
      }
    ) {
      id
      firstname
      lastname
      email
      phone
      address
      zip
      city
      country
      sensitive
      category
      periodend
    }
  }
`;

const GET_PROFILE = gql`
  query Profile($id: Float!, $key: String!) {
    profile(id: $id, key: $key) {
      id
      firstname
      lastname
      email
      phone
      address
      zip
      city
      country
      sensitive
      category
      periodend
    }
  }
`;

export {
  GET_PROFILE_CAPTCHA,
  ADD_PROFILE,
  SAVE_PROFILE,
  GET_PROFILE,
  ADD_MAILINGLIST,
  VERIFY_SMS_PROFILE,
};
