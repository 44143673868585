import React from "react";

interface InterfaceField {
  error?: string;
  title: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  type: string;
  placeholder: string;
  required?: boolean;
  autoComplete?: string;
}

function Field(props: InterfaceField) {
  const msg = props.error ? (
    <p className="help is-danger">{props.error}</p>
  ) : undefined;
  return (
    <div className="field is-horizontal">
      <div className="field-label is-normal">
        <label className="label">{props.title}</label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              onChange={props.onChange}
              value={props.value}
              name={props.name}
              className={"input " + (props.error ? "is-danger" : "")}
              type={props.type}
              placeholder={props.placeholder}
              required={props.required}
              autoComplete={props.autoComplete}
            />
          </div>
          {msg}
        </div>
      </div>
    </div>
  );
}

export default Field;
