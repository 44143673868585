import React, { useState, useEffect } from "react";
import Field from "./Field";
import UserInfo from "../interfaces/UserInfo";

interface InterfaceMembershipForm {
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    data: UserInfo,
    addToMailinglist: boolean
  ) => void;
  info?: UserInfo;
  disabled?: boolean;
  className?: string;
  newMember?: boolean;
  captcha?: string;
  renewCaptcha?: () => void;
  setCaptchaValue?: React.Dispatch<React.SetStateAction<string>>;
  captchaValue?: string;
  captchaError?: boolean;
}

function MembershipForm(props: InterfaceMembershipForm) {
  const [info, setInfo] = useState<UserInfo>({} as UserInfo);
  const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
  const [addToMailinglist, setAddToMailinglist] = useState<boolean>(false);

  useEffect(() => {
    if (props.info !== undefined) {
      setInfo(props.info || {});
    }
  }, [props.info]);

  const onChange = (evt: any) => {
    setInfo((cur) => {
      const change: any = {};
      const name = evt.target.name as keyof UserInfo;
      change[name] = evt.target.value as string;
      return { ...cur, ...change };
    });
  };

  const onChangeBool = (evt: any) => {
    setInfo((cur) => {
      const change: any = {};
      const name = evt.target.name as keyof UserInfo;
      change[name] = (evt.target.value as string) === "true";
      return { ...cur, ...change };
    });
  };

  let sensitive = "";

  if (typeof info.sensitive === "boolean") {
    sensitive = info.sensitive ? "true" : "false";
  }

  const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (props.newMember && !acceptedTerms) return;
    props.onSubmit(evt, info, addToMailinglist);
  };
  const extraFields = [];

  if (props.newMember) {
    extraFields.push(
      <div key="mailinglist" className="field is-horizontal">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={addToMailinglist}
              onChange={(evt) => setAddToMailinglist(evt.target.checked)}
            />{" "}
            Ja, jeg ønsker å motta nyhetsbrev fra SLM Oslo med informasjon om
            hva som skjer i klubben og hvilke arrangementer som kommer.
          </label>
        </div>
      </div>
    );
    extraFields.push(
      <div key="terms" className="field is-horizontal">
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={acceptedTerms}
              onChange={(evt) => setAcceptedTerms(evt.target.checked)}
            />{" "}
            Jeg har lest, forstått og vil følge{" "}
            <a href="https://slmoslo.no/medlemmer/regler-for-medlemskap/">
              reglene for medlemskap
            </a>{" "}
            i SLM Oslo.
          </label>
        </div>
      </div>
    );
  }

  let birthYear;
  if (props.newMember) {
    birthYear = (
      <Field
        title="Fødselsår"
        onChange={onChange}
        value={info.birthYear || ""}
        name="birthYear"
        type="number"
        placeholder="1990"
        autoComplete="bday-year"
      />
    );
  }

  let captcha;
  if (props.captcha) {
    let captchaError;
    if (props.captchaError) {
      captchaError = <div>Feil captcha, prøv på nytt!</div>;
    }
    captcha = (
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">Captcha</label>
        </div>
        <div className="field-body">
          <div className="field is-normal">
            <img src={props.captcha} alt="Captcha" />
            <span onClick={() => props.renewCaptcha && props.renewCaptcha()}>
              Oppdater
            </span>
            <br />
            {captchaError}
            <input
              onChange={(t) => {
                if (props.setCaptchaValue) {
                  props.setCaptchaValue(t.target.value);
                }
              }}
              value={props.captchaValue}
              name="captcha"
              className={"input " + (false ? "is-danger" : "")}
              type="text"
              required={true}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={onSubmit}>
      <fieldset
        disabled={props.disabled}
        className={`profile ${props.className}`}
      >
        <Field
          title="Fornavn"
          onChange={onChange}
          value={info.firstname || ""}
          name="firstname"
          type="text"
          placeholder="Ola"
          required={true}
          autoComplete="given-name"
        />
        <Field
          title="Etternavn"
          onChange={onChange}
          value={info.lastname || ""}
          name="lastname"
          type="text"
          placeholder="Normann"
          required={true}
          autoComplete="family-name"
        />
        <Field
          title="Epost"
          onChange={onChange}
          value={info.email || ""}
          name="email"
          type="email"
          placeholder="Email input"
          required={true}
          autoComplete="email"
        />
        <Field
          title="Mobilnummer"
          onChange={onChange}
          value={info.phone || ""}
          name="phone"
          type="tel"
          placeholder="499 99 999"
          required={true}
          autoComplete="tel"
        />
        {birthYear}
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Adresse</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <textarea
                  onChange={onChange}
                  value={info.address || ""}
                  name="address"
                  rows={2}
                  className="textarea"
                  placeholder="Textarea"
                  autoComplete="street-address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <Field
          title="Postnummer"
          onChange={onChange}
          value={info.zip || ""}
          name="zip"
          type="text"
          placeholder="0000"
          autoComplete="postal-code"
        />
        <Field
          title="Poststed"
          onChange={onChange}
          value={info.city || ""}
          name="city"
          type="text"
          placeholder="Cristiania"
          autoComplete="address-level1"
        />

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">
              Motta post fra klubben (hovedsaklig medlemsbevis hvis etterspurt)
            </label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    onChange={onChangeBool}
                    name="sensitive"
                    value={sensitive}
                    required={true}
                  >
                    <option value="" disabled={true}>
                      Velg
                    </option>
                    <option value="false">Ja</option>
                    <option value="true">Nei</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {extraFields}

        {captcha}

        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label"></label>
          </div>
          <div className="field-body">
            <div className="field is-normal">
              <div className="control">
                <button
                  type="submit"
                  disabled={
                    (props.newMember && !acceptedTerms) ||
                    sensitive === "" ||
                    info.phone === "" ||
                    info.email === "" ||
                    info.lastname === "" ||
                    info.firstname === ""
                  }
                  className="button is-link"
                >
                  Send inn
                </button>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
  );
}

export default MembershipForm;
