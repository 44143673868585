import React, { useState } from "react";
import SimplePageFrame from "../components/SimplePageFrame";
import { useQuery, useMutation } from "@apollo/client";

import {
  ADD_PROFILE,
  ADD_MAILINGLIST,
  GET_PROFILE_CAPTCHA,
  VERIFY_SMS_PROFILE,
} from "../schema/profile";

import MembershipForm from "../components/MembershipForm";
import UserInfo from "../interfaces/UserInfo";

function NewMember(props: { match: any }) {
  const [inUpdate, setInUpdate] = useState(false);

  const [info, setInfo] = useState<UserInfo & { price: number }>(
    {} as UserInfo & { price: number }
  );

  const [captcha, setCaptcha] = useState<{ key: string; captcha: string }>();
  const [captchaError, setCaptchaError] = useState<boolean>(false);
  const [captchaValue, setCaptchaValue] = useState<string>("");
  const [hasError, setError] = useState<undefined | string>();

  const [smsKey, setSmsKey] = useState<string>("");
  const [smsCode, setSmsCode] = useState<string>("");

  const { refetch: refetchCaptcha } = useQuery(GET_PROFILE_CAPTCHA, {
    onError: (err) => {
      setError(err.message);
    },
    onCompleted: (data) => {
      console.log(data);
      setCaptcha(data.getProfileCaptcha);
      setInUpdate(false);
    },
  });

  const renewCaptcha = async () => {
    setInUpdate(true);
    const rest = await refetchCaptcha();
    setCaptcha(rest.data.getProfileCaptcha);
    setInUpdate(false);
  };

  const [addProfile] = useMutation(ADD_PROFILE, {
    onCompleted: (data) => {
      console.log(data);

      if (data.registerNewProfile.key) {
        setSmsKey(data.registerNewProfile.key);
      }
      setInUpdate(false);
    },
    onError: (error) => {
      if (error.message === "WRONG CAPTCHA!") {
        renewCaptcha();
        setCaptchaError(true);
        return;
      }
      console.log(error);
      setError(error.message);
    },
  });

  const [verifySMS] = useMutation(VERIFY_SMS_PROFILE, {
    onCompleted: (data) => {
      console.log(data);

      if (data.verifyNewProfile) {
        setInfo(data.verifyNewProfile);
        setSmsKey("");
      }
      setInUpdate(false);
    },
    onError: (error) => {
      console.log(error);
      setError(error.message);
    },
  });

  const [addMailingList] = useMutation(ADD_MAILINGLIST);

  const submitData = (evt: any, data: UserInfo, addToMailingList: boolean) => {
    evt.preventDefault();
    setInUpdate(true);
    addProfile({
      variables: { ...data, captchaKey: captcha?.key, captchaValue },
    });
    if (addToMailingList) {
      //      addMailingList({ variables: { email: data.email } });
    }
  };

  if (hasError) {
    if (hasError === "PROFILE INVALID!") {
      return (
        <SimplePageFrame title="En feil har oppstått">
          <p>
            Vi beklager, av en eller annen grunn godtar ikke systemet vårt
            registeringen. Ta kontakt med{" "}
            <a href="mailto:medlem@slmoslo.no">medlem@slmoslo.no</a> slik at vi
            kan se på saken.
          </p>
        </SimplePageFrame>
      );
    }
    if (hasError === "Phonenumber not supported.") {
      return (
        <SimplePageFrame title="En feil har oppstått">
          <p>
            Vi beklager, av en eller annen grunn godtar ikke systemet vårt
            mobilnummeret du har oppgitt. Ta kontakt med{" "}
            <a href="mailto:medlem@slmoslo.no">medlem@slmoslo.no</a> slik at vi
            kan se på saken.
          </p>
        </SimplePageFrame>
      );
    }
    return (
      <SimplePageFrame title="En feil har oppstått">
        <p>Vi beklager, en feil har oppstått. Vennligst forsøk igjen senere.</p>
        <p>
          <pre>[{hasError}]</pre>
        </p>
      </SimplePageFrame>
    );
  }

  if (smsKey) {
    return (
      <SimplePageFrame title="Verifiser telefonnummer">
        <p>Vi har nå sendt en SMS til ditt mobilnummer.</p>
        <p>&nbsp;</p>
        <form
          onSubmit={(evt: React.FormEvent<HTMLFormElement>) => {
            evt.preventDefault();
            verifySMS({
              variables: { profileKey: smsKey, code: smsCode },
            });
          }}
        >
          <fieldset disabled={inUpdate} className={`profile`}>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"></label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="is-fullwidth">
                      <input
                        onChange={(t) => {
                          setSmsCode(t.target.value);
                        }}
                        value={smsCode}
                        name="smsverify"
                        className={"input " + (false ? "is-danger" : "")}
                        type="text"
                        required={true}
                        autoComplete="one-time-code"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"></label>
              </div>
              <div className="field-body">
                <div className="field is-normal">
                  <div className="control">
                    <button
                      type="submit"
                      disabled={
                        smsCode.length !== 6 && Number.isInteger(smsCode)
                      }
                      className="button is-link"
                    >
                      Verifiser
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </SimplePageFrame>
    );
  }

  if (info.hasOwnProperty("id")) {
    return (
      <SimplePageFrame title="Medlemskap registrert">
        <p>
          Vi har mottatt registeringen hos oss. For å fullføre registeringen og
          bli aktivt medlem må du betale årskontingenten i SLM Oslo.
        </p>
        <p>
          Du kan betale medlemskapet på to måter, via Vipps eller via bank. Når
          kontingenten er registert betalt vil du få en epost med bekreftelse.
        </p>

        <p>
          Betaling via Vipps:
          <br />
          Vippsnummer: <b>529 251</b> (SLM Oslo Medlem)
          <br />
          Merk betalingen med: <b>{info.id}</b>
          <br />
          Beløp: <b>{info.price},-</b>
        </p>

        <p>
          For å betale via bank benytt følgende informasjon:
          <br />
          Kontonummer: <b>1503.81.78447</b>
          <br />
          Merk betalingen med:{" "}
          <b>
            {info.id} {info.firstname} {info.lastname}
          </b>
          <br />
          Beløp: <b>{info.price},-</b>
        </p>
        <p>Denne informasjonen er også sendt på epost.</p>
      </SimplePageFrame>
    );
  }

  return (
    <SimplePageFrame title="Meld deg inn">
      <p>
        Her kan du registere ditt medlemskap. <b>NB!</b> Som et ledd i
        registeringen vil vi sende deg en SMS for å verifisere telefonnummeret.
      </p>
      <MembershipForm
        newMember={true}
        onSubmit={submitData}
        disabled={inUpdate}
        captcha={captcha?.captcha}
        renewCaptcha={renewCaptcha}
        setCaptchaValue={setCaptchaValue}
        captchaValue={captchaValue}
        captchaError={captchaError}
      />
    </SimplePageFrame>
  );
}

export default NewMember;
