import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_LOGGEDIN_USER } from "../schema/user";

function UserInfo() {
  const [user, setUser] = useState<string>();

  useQuery(GET_LOGGEDIN_USER, {
    onError: (err) => {
      console.error(err);
    },
    onCompleted: (data) => {
      setUser(data.getLogedInUser);
    },
  });

  if (user) {
    return <div>{user}</div>;
  } else {
    return <div />;
  }
}

export default UserInfo;
