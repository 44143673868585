import React, { useState } from "react";
import SimplePageFrame from "../components/SimplePageFrame";
import { useQuery, useMutation } from "@apollo/client";

import { SAVE_PROFILE, GET_PROFILE } from "../schema/profile";

import MembershipForm from "../components/MembershipForm";
import UserInfo from "../interfaces/UserInfo";

function Profile(props: { match: any }) {
  const params = {
    id: parseInt(props.match.params.id),
    key: props.match.params.key,
  };

  const [updated, setUpdated] = useState(false);
  const [inUpdate, setInUpdate] = useState(true);

  const [info, setInfo] = useState<UserInfo>({} as UserInfo);

  const [hasError, setError] = useState<undefined | string>();

  useQuery(GET_PROFILE, {
    variables: params,
    onError: (err) => {
      setError(err.message);
    },
    onCompleted: (data) => {
      setInfo(data.profile);
      setInUpdate(false);
    },
  });

  const hasBeenUpdated = () => {
    setUpdated(true);
    setTimeout(() => {
      setUpdated(false);
    }, 3000);
  };

  const [updateProfile] = useMutation(SAVE_PROFILE, {
    onCompleted: (data) => {
      hasBeenUpdated();
      setInfo(data.updateProfile);
      setInUpdate(false);
    },
  });

  const submitData = (evt: any, data: UserInfo) => {
    evt.preventDefault();
    setInUpdate(true);
    const out = { ...params, ...data };
    updateProfile({ variables: out });
  };

  const isUpdated = updated ? (
    <div className="updated">Infromasjonen er sendt inn!</div>
  ) : undefined;

  if (hasError) {
    return (
      <SimplePageFrame title="En feil har oppstått">
        <p>Vi beklager, en feil har oppstått. Vennligst forsøk igjen senere.</p>
        <p>
          <pre>[{hasError}]</pre>
        </p>
      </SimplePageFrame>
    );
  }

  return (
    <SimplePageFrame title="Din informasjon">
      <p>
        Dette er de persondataen vi har registert på deg. Du kan sende inn
        endringer her, så vil endringene bli behandlet av medlemsansvarlig.
      </p>
      {isUpdated}

      <MembershipForm
        onSubmit={submitData}
        disabled={updated || inUpdate}
        info={info}
      />

      <p>&nbsp;</p>
    </SimplePageFrame>
  );
}

export default Profile;
