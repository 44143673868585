import React, { useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  concat,
  HttpLink,
  ApolloLink,
} from "@apollo/client";

import "./App.scss";
import Privacy from "./views/Privacy";
import Index from "./views/Index";
import Profile from "./views/Profile";
import NewMember from "./views/NewMember";
import Login from "./views/Login";
import UserInfo from "./components/UserInfo";
import Search from "./views/Search";

function App() {
  const httpLink = new HttpLink({
    uri: "/graphql",
  });

  const authLink = new ApolloLink((operation, forward) => {
    if (
      sessionStorage.getItem("refreshToken") &&
      sessionStorage.getItem("accessToken")
    ) {
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          "x-refresh-token": sessionStorage.getItem("refreshToken"),
          "x-access-token": sessionStorage.getItem("accessToken"),
        },
      }));
    }
    return forward(operation);
  });

  const client = new ApolloClient({
    link: concat(authLink, httpLink),
    uri: "/graphql",
    cache: new InMemoryCache(),
  });

  const setAuth = (refreshToken: string, accessToken: string) => {
    window.sessionStorage.setItem("refreshToken", refreshToken);
    window.sessionStorage.setItem("accessToken", accessToken);
  };

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <div className="bg"></div>
        <UserInfo />
        <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route
              exact
              path="/login"
              render={() => <Login setAuth={setAuth} />}
            />
            <Route exact path="/personvern" component={Privacy} />
            <Route exact path="/newmember" component={NewMember} />
            <Route exact path="/search" component={Search} />
            <Route exact path="/profile/:id/:key" component={Profile} />
          </Switch>
        </BrowserRouter>
      </div>
    </ApolloProvider>
  );
}

export default App;
