import React from "react";
import SimplePageFrame from "../components/SimplePageFrame";

function Privacy() {
  return (
    <SimplePageFrame title="Søk medlemskap i SLM Oslo">
      <p>
        Scandinavian Leather Men Oslo er klubben kinkie menn som er interessert
        i andre menn. Eller menn som bare har en intresse for lær, gummi, BDSM,
        og så videre.
      </p>
      <p>Søk medlemskap her</p>
    </SimplePageFrame>
  );
}

export default Privacy;
