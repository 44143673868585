import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { SEARCH } from "../schema/search";

import LargePageFrame from "../components/LargePageFrame";

function Search(props: { match: any }) {
  const [inUpdate, setInUpdate] = useState(false);

  const [search, setSearch] = useState<string>("");

  const [result, setResult] = useState<any[]>([]);

  useQuery(SEARCH, {
    variables: {
      query: search,
    },
    onError: (err) => {
      console.error(err);
      setInUpdate(false);
    },
    onCompleted: (data) => {
      setResult(data.search);
      setInUpdate(false);
    },
  });

  const formatedResults =
    result &&
    result.map((el) => {
      return (
        <tr>
          <td>{el.id}</td>
          <td>{el.firstname}</td>
          <td>{el.lastname}</td>
          <td>{el.email}</td>
          <td>{el.phone}</td>
          <td>{el.category}</td>
          <td>{new Date(el.periodend).toISOString().substr(0, 10)}</td>
        </tr>
      );
    });

  return (
    <LargePageFrame title="Medlemssøk">
      <form
        onSubmit={(evnt) => {
          evnt.preventDefault();
        }}
      >
        <div className="field has-addons">
          <div className="control">
            <input
              className="input"
              type="text"
              placeholder=""
              value={search}
              onChange={(evt) => {
                setSearch(evt.target.value);
              }}
            />
          </div>
        </div>
      </form>
      <table className="table is-striped is-fullwidth">
        <thead>
          <tr>
            <th>#</th>
            <th>Fornavn</th>
            <th>Etternavn</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Status</th>
            <th>Utløp</th>
          </tr>
        </thead>
        {formatedResults}
      </table>
    </LargePageFrame>
  );
}

export default Search;
