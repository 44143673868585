import React, { useState } from "react";
import SimplePageFrame from "../components/SimplePageFrame";
import { useQuery, useMutation } from "@apollo/client";

import { AUTHENTICATE, AUTHENTICATE_TOTP } from "../schema/user";
import { Redirect } from "react-router";

function Login(props: {
  setAuth: (refreshToken: string, accessToken: string) => void;
}) {
  const [inUpdate, setInUpdate] = useState(false);

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [smsKey, setSmsKey] = useState<string>("");
  const [smsCode, setSmsCode] = useState<string>("");

  const [success, setSuccess] = useState<boolean>(false);

  const [hasError, setError] = useState<undefined | string>();

  const [authenticate] = useMutation(AUTHENTICATE, {
    onCompleted: (data) => {
      console.log(data);
      if (data.authenticate) {
        setSmsKey(data.authenticate);
      }
      setInUpdate(false);
    },
    onError: (error) => {
      console.log(error);
      setError(error.message);
    },
  });

  const [verifyTOTP] = useMutation(AUTHENTICATE_TOTP, {
    onCompleted: (data) => {
      if (data.otpVerify) {
        props.setAuth(data.otpVerify.refreshToken, data.otpVerify.accessToken);
        setSmsKey("");
        setSuccess(true);
      }
      setInUpdate(false);
    },
    onError: (error) => {
      console.log(error);
      setError(error.message);
    },
  });

  const submitLogin = (evt: any) => {
    evt.preventDefault();
    setInUpdate(true);
    if (smsKey && smsCode) {
      verifyTOTP({
        variables: { username: username, key: smsKey, otp: smsCode },
      });
    } else {
      authenticate({
        variables: { username, password },
      });
    }
  };

  if (success) {
    return <Redirect to="/search" />;
  }

  if (hasError) {
    if (hasError === "PROFILE INVALID!") {
      return (
        <SimplePageFrame title="En feil har oppstått">
          <p>
            Vi beklager, av en eller annen grunn godtar ikke systemet vårt
            registeringen. Ta kontakt med{" "}
            <a href="mailto:medlem@slmoslo.no">medlem@slmoslo.no</a> slik at vi
            kan se på saken.
          </p>
        </SimplePageFrame>
      );
    }
    if (hasError === "Phonenumber not supported.") {
      return (
        <SimplePageFrame title="En feil har oppstått">
          <p>
            Vi beklager, av en eller annen grunn godtar ikke systemet vårt
            mobilnummeret du har oppgitt. Ta kontakt med{" "}
            <a href="mailto:medlem@slmoslo.no">medlem@slmoslo.no</a> slik at vi
            kan se på saken.
          </p>
        </SimplePageFrame>
      );
    }
    return (
      <SimplePageFrame title="En feil har oppstått">
        <p>Vi beklager, en feil har oppstått. Vennligst forsøk igjen senere.</p>
        <p>
          <pre>[{hasError}]</pre>
        </p>
      </SimplePageFrame>
    );
  }

  if (smsKey) {
    return (
      <SimplePageFrame title="Login">
        <form onSubmit={submitLogin}>
          <fieldset disabled={inUpdate}>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">SMS kode</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input
                      onChange={(evt) => setSmsCode(evt.target.value)}
                      value={smsCode}
                      className={"input"}
                      type="text"
                      placeholder=""
                      required={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"></label>
              </div>
              <div className="field-body">
                <div className="field is-normal">
                  <div className="control">
                    <button
                      type="submit"
                      disabled={
                        smsCode.length !== 6 && Number.isInteger(smsCode)
                      }
                      className="button is-link"
                    >
                      Verifiser
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </SimplePageFrame>
    );
  }

  return (
    <SimplePageFrame title="Login">
      <form onSubmit={submitLogin}>
        <fieldset disabled={inUpdate}>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Brukernavn</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    onChange={(evt) => setUsername(evt.target.value)}
                    value={username}
                    className={"input"}
                    type="text"
                    placeholder=""
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Passord</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    onChange={(evt) => setPassword(evt.target.value)}
                    value={password}
                    className={"input"}
                    type="password"
                    placeholder=""
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label"></label>
            </div>
            <div className="field-body">
              <div className="field is-normal">
                <div className="control">
                  <button type="submit" className="button is-link">
                    Logg inn
                  </button>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </SimplePageFrame>
  );
}

export default Login;
