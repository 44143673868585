import { gql } from "@apollo/client";

export const AUTHENTICATE = gql`
  mutation authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password)
  }
`;

export const AUTHENTICATE_TOTP = gql`
  mutation otpVerify($username: String!, $key: String!, $otp: String!) {
    otpVerify(otp: $otp, key: $key, username: $username) {
      accessToken
      refreshToken
    }
  }
`;

export const GET_LOGGEDIN_USER = gql`
  query getuser {
    getLogedInUser
  }
`;
